/* eslint-disable @typescript-eslint/no-shadow */
import { createContext, FormEvent, ReactChild, useContext, useState } from 'react'
import { IUserContext } from '@/types/types'
import { MenuContext } from './MenuContext'
import { useNavigate } from 'react-router-dom' 
import axios from 'axios'

type Props = {
  children: ReactChild | ReactChild[]
}

const userContextDefault: IUserContext = {
  user: '',
  setUser: () => null,
  login: () => null,
  logout: () => null,
  error: '',
  loading: false,
  setLoading: () => null,
  token: '',
  setToken: () => null,
}

export const UserContext = createContext<IUserContext>(userContextDefault)

export function UserContextProvider({ children }: Props) {
  const [user, setUser] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [token, setToken] = useState<string>('')
  const {
    setActiveLocation,
    setActiveApps,
    setAppGroups,
    setActiveTab,
    setHelpContent,
    setShowModal,
    fetchAppGroups
  } = useContext(MenuContext)
  
  const navigate = useNavigate()

  const login = async (input: string, e: any, checked: boolean) => {
    if (e) {
      e.preventDefault()
    }
    setLoading(true)
    try {
      const res = await axios.get<string>(`${process.env.REACT_APP_API}Status/WhoAmI`, {
        headers: {
          accept: 'text/plain',
          Authorization: `Bearer ${input}`,
        },
      })
      console.log(res.data)
      setUser(res.data)
      setToken(input)
      setShowModal(false)
      fetchAppGroups(input)
      window.sessionStorage.setItem('token', input)
      window.sessionStorage.setItem('user', res.data)
      if (checked) {
        localStorage.setItem('token', input)
      } else {
        localStorage.removeItem('token')
      }
      return res.data
    } catch (err) {
      setShowModal(true)
      if (axios.isAxiosError(err)) {
        const serverError = err
        if (serverError?.response?.status === 401) {
          setError('Invalid API Token')
          setTimeout(() => {
            setError('')
          }, 3000)
        }
      } else {
        setError('Something went wrong')
        setTimeout(() => {
          setError('')
        }, 3000)
      }
    }
    setLoading(false)
    return undefined
  }

  const logout = (): void => {
    setUser('')
    setActiveLocation('/')
    setAppGroups([])
    setActiveTab('')
    setHelpContent('')
    setActiveApps([])
    setShowModal(false)
    window.sessionStorage.removeItem('user')
    window.sessionStorage.removeItem('token')
    navigate('/')
  }

  return (
    <UserContext.Provider
      value={{
        logout,
        login,
        user,
        error,
        loading,
        setLoading,
        token,
        setToken,
        setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUserContext = () => useContext(UserContext)

import { Footer, NavLink } from './Bottom.styles'
import NotificationsIcon from '@mui/icons-material/Notifications';
import MessageIcon from '@mui/icons-material/Message';
import HelpIcon from '@mui/icons-material/Help';
import { MenuContext } from 'context/MenuContext';
import { UserContext } from 'context/UserContext';
import { TranslationsContext } from 'context/TranslationsContext';

import { useContext, useEffect } from 'react';
import axios from 'axios';

type Props = {
  clickHandler: (c: string) => void
}

const Bottom = ({ clickHandler }: Props) => {
  const { activeLocation } = useContext(MenuContext)
  const { user } = useContext(UserContext)
  const { translations } = useContext(TranslationsContext)

  return (
    <Footer>
      <NavLink onClick={() => clickHandler('/blog')} $isActive={activeLocation === '/blog'} to="/blog">
        <MessageIcon />
        <span>{translations.blog}</span>
      </NavLink>
      <NavLink onClick={() => clickHandler('/help')} $isActive={activeLocation === '/help'} to={'/help'} data-testid='help'>
        <HelpIcon />
        <span>{translations.help}</span>
      </NavLink>
      {user && (
        <NavLink onClick={() => clickHandler('/webhooks')} $isActive={activeLocation === '/webhooks'} to="/webhooks">
          <NotificationsIcon />
          <span>WebHooks</span>
        </NavLink>
      )}
    </Footer>
  )
}

export default Bottom
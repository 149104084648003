import styled from 'styled-components'

export const Container = styled.header`
width: 100vw;
height: 85px;
background-color: white;
display: flex;
position: fixed;
align-items: center;
justify-content: space-between;
z-index: 10;
border-bottom: 1px solid #e5eff8;

transition: height 0.3s ease;
@media (min-width: 991px) {
  justify-content: flex-end;
}
`
export const BigLogo = styled.div`
display: none;
@media (min-width: 991px) {
  width: 100%;
  display: block;

margin-left: 3rem;
img {
  height: 40px;
  vertical-align: middle;
}
}
`
export const MobileMenu = styled.div<{ showMobileMenu: boolean }>`
display: flex;
height: 100%;
align-items: center;
margin-left: 2rem;
svg {
  width: 2.7rem;
  height: 2.7rem;
  transition: color 0.3s ease;
  color: ${props => (props.showMobileMenu ? '#006eff' : 'rgb(52, 73, 94)')};
  cursor: pointer;
}
@media (min-width: 991px) {
  display: none;
}
`

export const Login = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin: 25px 40px 25px 25px;
button {
  background-color: white;
  border: 1.5px solid #38b832;
  border-radius: 4px;
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    font-size: 12px;
    margin-right: 10px;
    color: #38b832;
    font-weight: 500;
  }
  .icon {
    font-size: 1.2rem;
    color: #38b832;
  }
}
`
export const UserInfo = styled.div`
margin: 25px 40px 25px 25px;
display: flex;
flex-direction: column;
align-items: flex-start;
flex-direction: row;
span {
  display: flex;
  text-align: center;
  flex-direction: column;
  text-transform: uppercase;
  font-weight: 500;
  color: black;
  jusify-content: center;
}
.user-icon {
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  color: black;
}
.user-icon:last-of-type {
  cursor: pointer;
}
`
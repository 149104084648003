import styled from 'styled-components'

export const Container = styled.div<{ showSecondLevelMenu: boolean, showMobileMenu: boolean }>`
  transition: width 0.3s ease-in-out;
  height: 100%;
  margin-top: 85px;
  overflow-x: hidden;
  z-index: 4;
  width: 290px;
  width: ${props => !props.showSecondLevelMenu ? '290px' : '0px' };
  background-color: rgb(247, 250, 253);
  @media (max-width: 991px) {
    transform: none;
    width: 290px;
  }
`

export const List = styled.ul`
  list-style: none;
  transition: all 0.3s ease;
  overflow-y: scroll;
  padding: 1rem;
  padding-bottom: 50px;
  height: 100%;
  min-width: 290px;
  display: flex;
  flex-direction: column;
  p {
    text-align: center;
    color: rgb(52, 73, 94);
  }
`

export const Back = styled.div`
  width: 100%;
  color: #87a2bc; 
  padding: 0px 0px 10px 0px;
  font-size: 10px;
  svg {
    cursor: pointer;
  }
`
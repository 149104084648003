import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-height: 100%;
  color: #34495e;
  overflow-y: scroll;
  background-color: #e5eff8;
  padding: 3rem 4rem;
  display: flex;
  flex-direction: column;
  margin-left: 86px;
  transition: all 0.3s ease;
  .transition {
    padding-top: 100px;
  }
  @media (max-width: 778px) {
    width: 100%;
    padding: 3rem 1rem;
  }
  @media (max-width: 991px) {
    margin-left: 0;
  }
`
export const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  .icon {
    vertical-align: top;
    margin-right: 0.5rem;
  }

  @media (max-width: 778px) {
    justify-content: center;
    font-size: 13px;
  }
`

export const NotificationURLS = styled.form`
  margin-top: 2rem;
  width: 100%;
  height: 100%;
`
export const InputContainer = styled.div<{ hasError: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0rem;
  position: relative;
  input {
    border-radius: 6px;
    padding: 1rem;
    margin-right: 1rem;
    height: 3rem;
    border: 0.5px solid transparent;
    border-color: ${props => (props.hasError ? 'red' : 'transparent')};
    width: 100%;
    background-color: white;
    letter-spacing: 0.5px;
    min-width: fit-content;
    box-shadow: #34495e2f 0px 10px 15px -2px;
    &:focus {
      border-color: #34495e3e;
      outline: none;
    }
  }
  button {
    background-color: white;
    width: 3rem;
    border: none;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: #34495e;
    box-shadow: #34495e2f 0px 10px 15px -2px;
    cursor: pointer;
    &:hover {
      background-color: #006eff;
      color: white;
    }
    &:active {
      transform: translateY(2px);
    }
    @media (max-width: 778px) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
`
export const ErrMessage = styled.p`
  color: red;
  left: 2px;
  font-size: 10px;
  position: absolute;
  top: 40px;
`
export const FormHeader = styled.div`
  width: 100%;
  height: 3.5rem;
  background-color: #006eff;
  color: white;
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-bottom: 0.5rem;
`
export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`
const loading = keyframes`
to {
     transform: rotate(-360deg);
   }
`
export const TestButton = styled.div<{ isSuccess: number | null | undefined | boolean; disabled: boolean }>`
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: #34495e;
  box-shadow: #34495e2f 0px 10px 15px -2px;
  cursor: pointer;
  background-color: white;
  background-color: ${props => props.isSuccess === 1 && '#38b832'};
  background-color: ${props => props.isSuccess === 0 && '#dd4c39;'};
  &:hover {
    background-color: ${props => props.isSuccess === 1 && '#38b832'};
    background-color: ${props => props.isSuccess === 0 && '#dd4c39;'};
  }
  &:active {
    transform: translateY(2px);
  }
  @media (max-width: 778px) {
    width: 2.5rem;
    height: 2.5rem;
  }
  .success {
    background-color: transparent;
    color: white;
  }
  .fail {
    background-color: transparent;
    color: white;
  }
  .icon-loading {
    animation-name: ${loading};
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
`

export const SendForm = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  display: -webkit-box;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
  -webkit-justify-content: end;
`
export const AddNewRow = styled.button`
  background-color: #2dad00;
  margin-top: 1.5rem;
  min-width: 8rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: none;
  color: white;
  box-shadow: #34495e2f 0px 10px 15px -2px;
  cursor: pointer;
  &:active {
    transform: translateY(2px);
  }
  span {
    font-size: 12px;
    font-weight: 500;
    padding-right: 0.5rem;
  }
  @media (max-width: 778px) {
    font-size: 10px;
    width: 6rem;
    height: 2.5rem;
  }
`
export const Button = styled.button`
  margin-bottom: 0;
  min-width: 8rem;
  height: 3rem;
  border: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  background-color: #006eff;
  color: white !important;
  box-shadow: #34495e2f 0px 10px 15px -2px;
  font-weight: 500;
  cursor: pointer;
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
  &:hover {
    p {
      transform: scale(1.05);
    }
  }
  p {
    transition: all 0.2s ease-in-out;
  }
  &:active {
    transform: translateY(2px);
  }
  span {
    margin-right: 0.5rem;
  }
  @media (max-width: 778px) {
    font-size: 10px;
    width: 6rem;
    height: 2.5rem;
  }
`
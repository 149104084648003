import styled from 'styled-components'

export const Modal = styled.div<{ showError: boolean, showMenuError: boolean }>`
width: 100%;
height: fit-content;
justify-content: space-between;
flex-directioon: column;
display: flex;
opacity: ${props => (props.showError  || props.showMenuError ? '1' : '0')};
padding: 10px;
background-color: #fdd9d6;
border-radius: 6px;
z-index: 100;
margin-bottom: 2rem;
transition: opacity 0.5s ease;
button {
  background: none;
  border: none;
  cursor: pointer;
  svg {
    color: #f44336;
  }
}
`
export const Content = styled.div`
display: flex;
width: 600px;
align-items: center;
p {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: #f44336;
  svg {
    margin-right: 0.5rem;
  }
}
`
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const ErrorContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e5eff8;
  color: #85a1bb;

  h1 {
    font-size: 7rem;
  }
  p {
    margin-top: 1rem;
    font-weight: 500;
    font-size: 1.5rem;
  }
`
export const BackLink = styled(Link)`
  text-decoration: none;
  color: white;
  background-color: #006eff;
  padding: 0.4rem 1rem;
  border-radius: 6px;
  margin-top: 2rem;
  display: felx;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
  &:hover svg {
    transform: translateX(8px);
  }
  svg {
    transition: all 0.5s ease;

    vertical-align: middle;
    margin-left: 0.5rem;
  }
`
import { Container, LoaderContainer, Loader, ErrorContainer, IFrame } from './Apps.styles'
import { useLocation } from 'react-router-dom'
import { useContext, useEffect, memo, useState } from 'react'
import { UserContext } from 'context/UserContext'
import { ErrorModalContext } from 'context/ErrorContext'
import { IFrameURLResponse } from 'types/types'
import axios from 'axios'
import ErrorModal from 'components/Errors/ErrorModal/ErrorModal'


type IFrameSrc = {
  id: string
  src: string
}

function Apps() {
  const location = useLocation()
  const { token } = useContext(UserContext)

  const [loading, setLoading] = useState(false)
  const [frameSrc, setFrameSrc] = useState<Array<IFrameSrc>>([{ id: '', src: '' }])
  const id: string = location.pathname.split('/')[2]

  const { handleErrorModal, showError } = useContext(ErrorModalContext)
  useEffect(() => {
    const setFrameUrl = async () => {
      if (frameSrc.find(el => el.id === id)) {
        return
      }
      setLoading(true)
      try {
        const res = await axios.get<IFrameURLResponse>(`${process.env.REACT_APP_API}Frames/GetFrameUrlById?applicationId=${id}`, {
          headers: {
            accept: 'text/plain',
            Authorization: `Bearer ${token}`,
          },
        })
        const catchErr = await axios.get(res.data.urls[0])
        setFrameSrc([...frameSrc, { id: id, src: res.data.urls[0] }])
      } catch (error: any) {
        handleErrorModal(error.message)
      } finally {
        setTimeout(() => {
          setLoading(false)
        }, 3000)
      }
    }
    setFrameUrl()
  }, [location])

  return (
    <Container $loading={loading}>
      {loading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      {!loading && showError && (
        <ErrorContainer>
          <ErrorModal />
        </ErrorContainer>
      )}
      {!showError &&
        frameSrc.map(el => <IFrame 
          key={el.id} 
          src={el.src} 
          title={id} 
          $display={el.id === id} 
          data-testid="iframe" 
          initial={{ opacity: 0 }} 
          animate={{ opacity: 1 }} 
          exit={{ opacity: 0 }}
        />)}
    </Container>
  )
}

export default memo(Apps)

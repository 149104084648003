import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import ReplayIcon from '@mui/icons-material/Replay'
import { IWebHookUrl } from 'types/types'
import { valid_url } from './regExps'

export const renderCorrectIcon = (
  testedWebHook: number | null | undefined,
  index: number,
  isSuccess: number | null | undefined,
  loading: boolean
) => {
  if (testedWebHook === index && isSuccess === 1) {
    return <CheckIcon className="success" />
  } else if (testedWebHook === index && isSuccess === 0) {
    return <CloseIcon className="fail" />
  } else if ((testedWebHook !== index && loading === false) || (testedWebHook !== index && loading === true)) {
    return <ReplayIcon />
  } else if (testedWebHook === index && loading === true) {
    return <ReplayIcon className="icon-loading" />
  } else {
    return <ReplayIcon />
  }
}

export const validateInputData = (arr: IWebHookUrl[], setErrorMessage: (c: string[]) => void) => {
  const errors: Array<string> = []
  errors.length = arr.length
  arr.map((value, index) => {
    if (value.url === '' || !value.url.match(valid_url)) {
      errors[index] = 'Please provide valid URL'
    }
  })
  setErrorMessage(errors)
  return errors
}

import styled from 'styled-components'

export const Container = styled.div`
position: relative;
width: 100vw;
height: 100vh;
display: flex;
flex-direction: column;
`
export const MobileBackground = styled.div<{ showMobileMenu: boolean }>`
position: fixed;
background-color: #0000004f;
display: ${props => (props.showMobileMenu ? 'block' : 'none')};
width: 100vw;
z-index: 8;
height: 100vh;
top: 0;
left: 0;
transition: all 0.3s ease;

@media (min-width: 991px) {
  display: none;
}
`
export const Content = styled.div`
display: flex;
height: 100vh;
position: relative;
`
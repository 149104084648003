import { useState } from 'react'

const useErrorModal = () => {
  const [showError, setShowError] = useState<boolean>(false)
  const [content, setContent] = useState<string>('')
  const [showMenuError, setShowMenuError] = useState(false)
  const [menuErrorContent, setMenuErrorContent] = useState<string>('')
  const [showWebHooksError, setShowWebHooksError] = useState(false)
  const [webHooksErrorContent, setWebHooksErrorContent] = useState('')

  function handleErrorModal(cnt: string) {
    setShowError(true)
    if (cnt) {
      setContent(cnt)
    } else {
      setContent('Something went wrong.')
    }
  }

  function handleMenuErrorModal(cnt: string) {
    setShowMenuError(true)
    if (cnt) {
      setMenuErrorContent(cnt)
    } else {
      setMenuErrorContent('Something went wrong.')
    }
  }

  function handleWebHooksError(cnt: string) {
    setShowWebHooksError(true)
    if (cnt) {
      setWebHooksErrorContent(cnt)
    } else {
      setWebHooksErrorContent('Something went wrong.')
    }
  }

  const closeMenuErrorModal = () => {
    setShowMenuError(false)
  }

  const closeModal = () => {
    setShowError(false)
  }

  return {
    closeMenuErrorModal,
    menuErrorContent,
    handleErrorModal,
    content,
    showError,
    closeModal,
    setShowError,
    handleMenuErrorModal,
    showMenuError,
    showWebHooksError,
    handleWebHooksError 
  }
}

export default useErrorModal

import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { Container, Modal, CloseIcon, Form, CheckboxContainer } from './Login.styles'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { UserContext } from 'context/UserContext'
import { MenuContext } from 'context/MenuContext'
import { TranslationsContext } from 'context/TranslationsContext'

function Login() {
  const [input, setInput] = useState<string>('')
  const [checked, setChecked] = useState<boolean>(false)
  const { error, login } = useContext(UserContext)
  const { setShowModal } = useContext(MenuContext)
  const { translations } = useContext(TranslationsContext)

  useEffect(() => {
    const savedToken = localStorage.getItem('token')
    if (savedToken) {
      setInput(savedToken)
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [])

  const handleChecked = (e: ChangeEvent<HTMLInputElement>): void => {
    setChecked(!checked)
  }

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>): void => {
    setInput(e.target.value)
  }


  return (
    <Container data-testid='auth-modal'>
      <Modal>
        <CloseIcon onClick={() => setShowModal(false)} />
        {error && (setShowModal(true), (<p>{translations.invalid_api_token}</p>))}
        <Form onSubmit={e => login(input, e, checked)}>
          <label htmlFor="password">
            <LockOpenIcon className="icon" />
            {translations.provide_api_token}
          </label>
          <input 
            data-testid='password' 
            type="password" 
            value={input} 
            onChange={handleChangeInput} 
            autoComplete="off" 
            id="password" 
            name="password" 
          />
          <CheckboxContainer>
            <input type="checkbox" onChange={handleChecked} checked={checked} id="token" name="token" />
            <label htmlFor="token">{translations.remember_api_token}</label>
          </CheckboxContainer>
          <button type="submit" disabled={input === ''} data-testid='login'>
            <span>{translations.authorize}</span>
            <LockOpenIcon className="icon" />
          </button>
        </Form>
      </Modal>
    </Container>
  )
}

export default Login

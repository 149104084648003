import { Dispatch, SetStateAction, useContext } from 'react'
import { MenuContext } from 'context/MenuContext'
import { AppGroup, NavItem } from './AppGroups.styles';
import { IAppGroup } from 'types/types';
import { renderIcon } from 'utils/SidebarHelper'

type Props = {
  itemToDisplayAtSecondLevel: IAppGroup[]
  setActiveShowMore: Dispatch<SetStateAction<string>>;
}

const AppGroups = ({ itemToDisplayAtSecondLevel, setActiveShowMore }: Props) => {
  const { activeLocation, setActiveLocation } = useContext(MenuContext)

  const clickHandler = (item: IAppGroup) => {
    setActiveLocation(item.displayName)
    setActiveShowMore('hideShowMore')
  }

  return (
    <AppGroup initial={{ opacity: 0.5 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      {itemToDisplayAtSecondLevel.map((item, index) => (
        <NavItem
          key={index}
          isActiveGroup={item.displayName === activeLocation}
          onClick={() => clickHandler(item)}
        >
          {renderIcon(item.id)}
          <span>{item.displayName}</span>
        </NavItem>
      ))}
    </AppGroup>
  )
}

export default AppGroups
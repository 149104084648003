import styled, { keyframes } from 'styled-components'

function Spinner() {
  return <SpinnerContainer data-testid="spinner" />
}

const spinner = keyframes`
 to {
      transform: rotate(1turn);
    }
`

const SpinnerContainer = styled.span`
  display: block;
  margin: 0;
  margin: 1rem;
  padding: 0;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-radius: 50%;
  border: 3px solid #006eff;
  border-bottom-color: transparent;
  position: relative;
  animation-name: ${spinner};
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`
export default Spinner

import { Dispatch, SetStateAction, useContext, useEffect } from 'react'
import { Container, Nav } from './AsidePrimary.styles'
import Top from './Top/Top';
import Bottom from './Bottom/Bottom'
import { UserContext } from 'context/UserContext'
import { MenuContext } from 'context/MenuContext'
import { useNavigate } from 'react-router-dom'
import { IAppGroup } from 'types/types'

type Props = {
  itemToDisplayAtFirstLevel: IAppGroup[]
  setActiveShowMore: Dispatch<SetStateAction<string>>;
  activeShowMore: string
  secondLevelMenuHandler: (c: string) => void
  locationsWithHiddenSecondLevelMenu: Array<string>;
}

function AsidePrimary({ itemToDisplayAtFirstLevel, setActiveShowMore, activeShowMore, secondLevelMenuHandler, locationsWithHiddenSecondLevelMenu }: Props) {
  const { appGroups, setActiveLocation, activeLocation, setShowMobileMenu, showMobileMenu, setActiveTab, activeApps, allApps } = useContext(MenuContext)
  const { user } = useContext(UserContext)
  const navigate = useNavigate()

  const clickHandler = (location: string): void => {
    setActiveLocation(location)
    setShowMobileMenu(false)
    if (showMobileMenu && !locationsWithHiddenSecondLevelMenu.includes(location)) {
      secondLevelMenuHandler(location)
      setShowMobileMenu(true)
    } else {
      secondLevelMenuHandler(location)
    }
  }

  useEffect(() => {
    if ((activeLocation === '/' || !activeLocation) && activeApps[0] && appGroups[0]) {
      setActiveLocation(appGroups[0].displayName)
      setActiveTab(activeApps[0].id)
      navigate(`/apps/${activeApps[0].id}`)
    }
    if (activeLocation === 'All' && allApps.length) {
      setActiveLocation(appGroups[0].displayName)
      setActiveTab(allApps[0].id)
      navigate(`/apps/${allApps[0].id}`)
    }
  }, [allApps, user, activeApps])


  return (
    <Container>
      <Nav>
      <Top 
        itemToDisplayAtFirstLevel={itemToDisplayAtFirstLevel} 
        setActiveShowMore={setActiveShowMore} 
        activeShowMore={activeShowMore} 
        clickHandler={clickHandler} 
      />
      <Bottom clickHandler={clickHandler}/>
      </Nav>
    </Container>
  )
}

export default AsidePrimary

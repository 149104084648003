import styled from 'styled-components'
import config from '../../config.json'
import { useContext, useEffect, useState } from 'react'
import { MenuContext } from 'context/MenuContext'
import axios from 'axios'
import { UserContext } from 'context/UserContext'
import { motion } from 'framer-motion'

function Help() {
  const { helpContent } = useContext(MenuContext)
  const { user } = useContext(UserContext)
  const [iframeUrl, setIframeUrl] = useState(config.help_url)

  useEffect(() => {
    const checkIfUrlExistsAndSetContent = async () => {
      const res = await axios.get(`${config.help_custom_url}${helpContent}`)
      if (res.data) {
        setIframeUrl(`${config.help_custom_url}${helpContent}`)
      }
    }
    checkIfUrlExistsAndSetContent()
  }, [helpContent])

  return (
      <IFrame user={user} src={iframeUrl} title="HELP" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}/>
  )
}

const IFrame = styled(motion.iframe)<{ user: string }>`
  border: none;
  background-color: #e5eff8;
  min-height: 100%;
  width: 100%;
  padding-top: 85px;
  margin-left: 0;
  transition: all 0.3s ease;
  margin-left: 86px;
  @media (max-width: 991px) {
    margin-left: 0;
  }
`
export default Help

import { TopLinks, NavItem, NavLink } from './Top.styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { renderIcon } from 'utils/SidebarHelper'
import { MenuContext } from 'context/MenuContext';
import { UserContext } from 'context/UserContext';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { IAppGroup } from 'types/types';
import { TranslationsContext } from 'context/TranslationsContext';

type Props = {
  itemToDisplayAtFirstLevel: IAppGroup[]
  setActiveShowMore: Dispatch<SetStateAction<string>>;
  activeShowMore: string
  clickHandler: (c: string) => void
}
const Top = ({ itemToDisplayAtFirstLevel, setActiveShowMore, activeShowMore, clickHandler }: Props) => {
  const { setActiveLocation, activeLocation, setShowMobileMenu, appGroups, allApps } = useContext(MenuContext)
  const { user } = useContext(UserContext)
  const { translations } = useContext(TranslationsContext);

  const tabArr = ['/webhooks', '/blog', '/help']
  
  return (
    <TopLinks>
      {user ? (
        <div className='show-more-container'>
          {itemToDisplayAtFirstLevel.map(group => (
            <NavItem
              key={group.displayName}
              onClick={() => {
                setActiveLocation(group.displayName);
                clickHandler(group.displayName)
              }}
              isActiveGroup={group.displayName === activeLocation}
            >
              {renderIcon(group.id)}
              <span>{group.displayName}</span>
            </NavItem>
          ))}
          {appGroups.length > 3 && allApps.length && 
          <NavItem 
            isActiveGroup={(activeShowMore === 'showMore' || activeShowMore === 'hideShowMore') && !tabArr.includes(activeLocation)} 
            data-testid='showMore'
            onClick={() => {
              setActiveShowMore('showMore'); 
              setActiveLocation('showMore');
              clickHandler('showMore')

            }}
            >
              <MoreHorizIcon />
              <div className='show-more' />
          </NavItem>}
        </div>
      ) : (
        <NavLink $isActive={location.pathname === '/'} onClick={() => {
          setShowMobileMenu(false); 
          clickHandler('/')
        }} to="/">
          <DashboardIcon />
          <span>{translations.home}</span>
        </NavLink>
      )}
  </TopLinks>
  )
}

export default Top
import styled from 'styled-components'
import config from '../../config.json'
import { useContext } from 'react'
import { UserContext } from 'context/UserContext'
import { motion } from 'framer-motion';

function Blog() {
  const { user } = useContext(UserContext)

  return (
      <IFrame 
        user={user} 
        title="BLOG" 
        src={config.blog_url} 
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        exit={{ opacity: 0 }}
      />
  ) 
}

const IFrame = styled(motion.iframe)<{ user: string }>`
border: none;
  background-color: #e5eff8;
  min-height: 100%;
  width: 100%;
  margin-left: 0;
  padding-top: 85px;
  transition: all 0.3s ease;
  margin-left: 86px;
  @media (max-width: 991px) {
    margin-left: 0;
  }
`

export default Blog

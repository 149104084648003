import Spinner from 'components/Spinner/Spinner'
import { UserContext } from 'context/UserContext'
import { ReactChild, useContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

type Props = {
  children: ReactChild
}

function ProtectedRoute({ children }: Props): any {
  const { token, user } = useContext(UserContext)
  const [loading, setLoading] = useState(true)

  let tokenFromSession;
  let userFromSession;

  const fetchDataFromSessionStorage = () => {
    tokenFromSession = sessionStorage.getItem(token)
    userFromSession = sessionStorage.getItem(user)
  }
  useEffect(() => {
    fetchDataFromSessionStorage()
    setLoading(false)
  }, [])

  if (loading) {
    return <Spinner />
  }
  if (!token || !user) {
    return <Navigate to="/unauthorized" replace />
  } else {
    return children
  }
}

export default ProtectedRoute

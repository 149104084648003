import { UserContext } from '../context/UserContext';
import { MenuContext } from '../context/MenuContext';
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'

const useAuthFormUrl = () => {
  const { login, setToken, setUser, setLoading } = useContext(UserContext)
  const { fetchAppGroups, setActiveTab, setActiveLocation, allApps, appGroups } = useContext(MenuContext)
  const search = window.location.search
  const params = new URLSearchParams(search)
  const pathname = window.location.pathname

  const authorizeUser = async () => {
    let url = window.location.href;

    setLoading(true)
    const activeApp = pathname.split('/')[2]
    const token = params.get('token')
    const userItem = window.sessionStorage.getItem('user')
    const tokenItem = window.sessionStorage.getItem('token')
  
    if (!token && !tokenItem) {
      return
    }
    if (token && activeApp) {
      url = window.location.href;
      setToken(token)
      login(token)
      fetchAppGroups(token)
    } 
    if (token) {
      url = window.location.href;
      setToken(token)
      login(token)
      fetchAppGroups(token)
    } 
    if (userItem && tokenItem) {
      setToken(tokenItem)
      setUser(userItem)
      fetchAppGroups(tokenItem)
    } 
    window.history.replaceState({}, '', `/apps/${activeApp}`)
    setActiveLocation('All')
    setActiveTab(activeApp)
    setLoading(false)
  }
  return { authorizeUser }
}

export default useAuthFormUrl
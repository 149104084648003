import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const TopLinks = styled.div`
  min-width: 100%;
  display: flex;
  overflow-y: scroll;
  background-color: white;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  justify-content: flex-start;
  .show-more-container {
    width: 100%;
  }
`
export const NavItem = styled.button<{ isActiveGroup: boolean }>`
  text-decoration: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  justify-content: center;
  width: 100% !important;
  min-height: 86px;
  max-height: 86px;
  cursor: pointer;
  border: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: ${props => (props.isActiveGroup ? '#006eff' : 'transparent')};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  svg {
    color: ${props => props.isActiveGroup ? 'white' : 'rgb(52, 73, 94);'};
    height: 30px;
    width: 30px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  }
  span {
    font-size: 10px;
    font-weight: 400;
    color: ${props => (props.isActiveGroup ? 'white' : 'rgb(52, 73, 94);;')};
  }  
`

export const NavLink = styled(Link)<{ $isActive: boolean }>`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  justify-content: center;
  min-width: 100%;
  height: 86px;
  cursor: pointer;
  border: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px !important;
  background-color: ${props => (props.$isActive ? '#006eff' : 'transparent')};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  svg {
    color: ${props => props.$isActive ? 'white' : 'rgb(52, 73, 94)'};
    height: 30px;
    width: 30px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  }
  span {
    font-size: 10px;
    font-weight: 400;
    color: ${props => (props.$isActive ? 'white' : 'rgb(52, 73, 94)')};
  }
  &:last-child {
    border-bottom-right-radius: 0;
  }
`
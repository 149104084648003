import styled from 'styled-components'
import logo from 'img/logo.svg'
import Spinner from './Spinner';
import { motion } from 'framer-motion';

const GlobalSpinner = () => {
  return (
    <Container>
        <Spinner />
        <Logo>
            <img src={logo} alt='logo' />
        </Logo>
    </Container>
  )
}

const Container = styled.div`
    background-color: #e5eff8;
    position: absolute;
    min-width: 100vw;
    min-height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span {
        width: 100px;
        height: 100px;
        animation-duration: s
    }
`

const Logo = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    img {
        width: 150px;
        position: fixed;
        bottom: 20px;
    }
`

export default GlobalSpinner
import { Routes, Route } from 'react-router-dom'
import Help from '../pages/Help/Help'
import Apps from '../pages/Apps/Apps'
import WebHooks from '../pages/WebHooks/WebHooks'
import Blog from '../pages/Blog/Blog'
import Home from '../pages/Home/Home'
import Error from '../pages/Error/Error'
import ProtectedRoute from './ProtectedRoute'
import { AnimatePresence } from 'framer-motion';

function AppRoutes() {

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/help" element={<Help />} />
        <Route
          path="/webhooks"
          element={
            <ProtectedRoute>
              <WebHooks />
            </ProtectedRoute>
          }
        />
        <Route
          path="/apps/:id"
          element={
            <ProtectedRoute>
              <Apps />
            </ProtectedRoute>
          }
        />
        <Route path="/unauthorized" element={<Error status={403} message="Authorization required." showBackButton={true} />} />
        <Route path="*" element={<Error status={404} message="Seems there is nothig here." showBackButton={true} />} />
      </Routes>
    </AnimatePresence>
  )
}

export default AppRoutes

import { IActiveApp } from '@/types/types'
import { SearchForm } from './SearchBar.styles'
import { useState, useContext } from 'react'
import { TranslationsContext } from 'context/TranslationsContext';
import SearchIcon from '@mui/icons-material/Search';

type Props = {
  setList: (c: IActiveApp[]) => void
  defaultList: Array<IActiveApp>
}

function SearchBar({ setList, defaultList }: Props) {
  const [input, setInput] = useState<string>('')
  const { translations } = useContext(TranslationsContext)

  const updateInput = async (i: string) => {
    const filteredList: Array<IActiveApp> = defaultList.filter(link => {
      return link.displayName.toLowerCase().includes(i.toLocaleLowerCase())
    })
    setInput(i)
    setList(filteredList)
  }

  return (
    <SearchForm data-test="search">
      <label>
        <SearchIcon />
      </label>
      <input placeholder={`${translations.search}...`} value={input} role="search" onChange={e => updateInput(e.target.value)} />
    </SearchForm>
  )
}

export default SearchBar

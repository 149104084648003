/* eslint-disable @typescript-eslint/no-unused-expressions */
import { ErrorModalContext } from 'context/ErrorContext'
import { useContext } from 'react'
import { Modal, Content } from './ErrorModal.styles';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';

function ErrorModal() {
  const { content, closeModal, showError, showMenuError } = useContext(ErrorModalContext)

  return (
    <Modal data-testid="modal" showError={showError} showMenuError={showMenuError}>
      <Content>
        <p><WarningIcon />{content}</p>
      </Content>
      <div>
        <button onClick={() => closeModal()}>
          <CloseIcon />
        </button>
      </div>
    </Modal>
  )
}

export default ErrorModal

import styled from 'styled-components'

export const Container = styled.div`
width: 100vw;
height: 100vh;
background-color: #e5eff8 !important;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
p {
    color: rgb(52, 73, 94);
    font-size: 35px;
}
span {
    color: rgb(52, 73, 94);
    font-size: 35px;

}
button {
    background-color: #006eff;
    border: none;
    color: white;
    padding: 1rem 1.5rem;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    transition: all 0.3s ease;
    text-transform: uppercase;
    line-heigth: 30px;
    font-size: 12px;
    letter-spacing: 1px;
    &:hover {
        background-color: #151938;
        transform: scale(1.05);
    }
}  
`

import { MenuContext } from 'context/MenuContext'
import { ReactChild, useContext, useEffect, useState } from 'react'
import Login from 'components/Login/Login'
import { Container, Content, MobileBackground  } from './Layout.styles'
import Header from '../Header/Header'
import Aside from '../Aside/Aside'
import GlobalSpinner from '../Spinner/GlobalSpinner'
import { UserContext } from 'context/UserContext'
import { TranslationsContext } from 'context/TranslationsContext'

type Props = {
  children: ReactChild | ReactChild[]
}

function Layout({ children }: Props) {
  const { showModal, setShowMobileMenu, showMobileMenu } = useContext(MenuContext)
  const [showGlobalLoader, setShowGlobalLoader] = useState<boolean>(false);
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (user) {
      setShowGlobalLoader(true)
      setTimeout(() => {
        setShowGlobalLoader(false)
      }, 3000)
    }
  }, [user])
  
  return (
    <Container>
      {showGlobalLoader && <GlobalSpinner />}
      <Header />
      <Content>
        <MobileBackground showMobileMenu={showMobileMenu} onClick={() => setShowMobileMenu(false)} />
        <Aside />
        {showModal && <Login />}
        {children}
      </Content>
    </Container>
  )
}

export default Layout

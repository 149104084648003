import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { Container, List, Back } from './AsideSecondary.styles'
import { MenuContext } from 'context/MenuContext'
import SearchBar from 'components/SearchBar/SearchBar'
import { IActiveApp, IAppGroup } from 'types/types'
import { ErrorModalContext } from 'context/ErrorContext'
import ErrorModal from 'components/Errors/ErrorModal/ErrorModal'
import AppGroups from './AppGroups/AppGroups';
import AppItems from './AppItems/AppItems'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

type Props = { 
  itemToDisplayAtSecondLevel: IAppGroup[]
  activeShowMore: string
  setShowSecondLevelMenu: Dispatch<SetStateAction<boolean>>;
  locationsWithHiddenSecondLevelMenu: Array<string>;
  setActiveShowMore: Dispatch<SetStateAction<string>>;
  showSecondLevelMenu: boolean

}
function AsideSecondary( { 
  itemToDisplayAtSecondLevel, 
  activeShowMore, 
  setActiveShowMore, 
  locationsWithHiddenSecondLevelMenu, 
  setShowSecondLevelMenu, 
  showSecondLevelMenu }: Props) {
  const { activeLocation, allApps, activeApps, setActiveLocation, showMobileMenu } = useContext(MenuContext)
  const { showMenuError } = useContext(ErrorModalContext)

  const [defaultList, setDefaultList] = useState<Array<IActiveApp>>([])
  const [list, setList] = useState<Array<IActiveApp>>([])


  useEffect(() => {
    if (locationsWithHiddenSecondLevelMenu.includes(activeLocation)) {
      return
    } else if (activeLocation === '!all' || activeLocation === 'Wszystkie' || activeLocation === 'All') {
      setDefaultList(allApps)
      setList(allApps)
    } else {
      const arr: Array<IActiveApp> = allApps.filter(el => el.tags.items[1].displayName === activeLocation)
      setDefaultList(arr)
      setList(arr)
    }
  }, [activeLocation, activeApps, allApps])

  useEffect(() => {
    if (locationsWithHiddenSecondLevelMenu.includes(activeLocation)) {
      setShowSecondLevelMenu(true)
    } else {
      setShowSecondLevelMenu(false)
    }
  }, [activeLocation])


  return (
    <Container showSecondLevelMenu={showSecondLevelMenu} showMobileMenu={showMobileMenu}>
      {showMenuError && <ErrorModal />}
      <List>

        {/* app groups as needed */}
        {activeShowMore === 'showMore' && itemToDisplayAtSecondLevel.filter(el => el.displayName === activeLocation) && (
          <AppGroups itemToDisplayAtSecondLevel={itemToDisplayAtSecondLevel} setActiveShowMore={setActiveShowMore} />
        )}

        {/* search bar */}
        {(activeShowMore === 'hideShowMore' || activeShowMore === '') && (
          <SearchBar setList={setList} defaultList={defaultList} />
        )}
        {/* back button */}
        {activeShowMore === 'hideShowMore' && itemToDisplayAtSecondLevel.filter(el => el.displayName === activeLocation) && (
          <Back>
            <KeyboardBackspaceIcon 
              onClick={() => {
                setActiveLocation('')
                setActiveShowMore('showMore'); 
              }}
            />
          </Back>
        )}
        {/* apps */}
        <AppItems 
          tabArr={locationsWithHiddenSecondLevelMenu} 
          list={list} 
          activeShowMore={activeShowMore} 
          setShowSecondLevelMenu={setShowSecondLevelMenu}
          showSecondLevelMenu={showSecondLevelMenu}
        />
      </List>
    </Container>
  )
}

export default AsideSecondary

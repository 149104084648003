import { MenuContext } from 'context/MenuContext'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { Container, Wrapper, MainContent, Content, Divider, Hero, UserMessage } from './Home.styles'
import heroImg from 'img/home-img.svg'
import logo from 'img/logo.svg'
import { useContext } from 'react'
import { motion } from 'framer-motion';
import { UserContext } from 'context/UserContext'
import notFoundImg from 'img/error.png';
import { TranslationsContext } from 'context/TranslationsContext'
import GlobalSpinner from 'components/Spinner/GlobalSpinner'

function Home() {
  const { setShowModal, allApps } = useContext(MenuContext)
  const {  user } = useContext(UserContext);
  const { translations, translationsLoading } = useContext(TranslationsContext)
  
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='transition'>
       {user && !allApps.length ? (
         <UserMessage>
          <p>Sorry, you don't have any active application.</p>
          <img src={notFoundImg} />
          </UserMessage>
       ) : (
        <Container> 
            <Wrapper>
              <MainContent>
                <Content>
                  <img src={logo} />
                  <Divider />
                  <button onClick={() => setShowModal(true)}>
                    <span>{translations.authorize}</span>
                    <LockOpenIcon className="icon" />
                  </button>
                </Content>
                <Hero>
                  <img src={heroImg} />
                </Hero>
              </MainContent>
            </Wrapper>
          <Wrapper />
        </Container>
       )} 
    </motion.div>
  )
}

export default Home

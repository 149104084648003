import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from 'context/UserContext';


export const checkBrowserLanguage = (): string => {
  const lang = navigator.language;
  if (lang === 'pl' || lang === 'pl-PL') {
    return 'pl-PL'
  } else {
    return 'en-GB'
  }
}
export const useFetchTranslations = () => {
  const [translations, setTranslations] = useState({})
  const [translationsLoading, setTranslationsLoading] = useState<boolean>(false)
  const [translationsError, setTranslationsError] = useState<string>('')
  const { token } = useContext(UserContext)

  const fetchTranslations = async (): Promise<void> => {
    setTranslationsLoading(true);
    const lang = checkBrowserLanguage();
    try {
      const res = await axios.get(`${process.env.REACT_APP_API}TranslateApp/GetTranslatedTexts?lang=${lang}`, {
        headers: {
          'Content-type': 'application/json',
        }
      })
      setTranslations(res.data)
      setTranslationsLoading(false)
    } catch (error: any) {
      setTranslationsError(error)
      setTranslationsLoading(false)
    }
  }

  useEffect(() => {
    fetchTranslations()
    return setTranslations({})
  }, [])

  return { translations, translationsError, translationsLoading, fetchTranslations }
}

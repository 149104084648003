import { useState, useEffect } from 'react'
import { NotificationURLS, FormHeader, InputContainer, ErrMessage } from '../WebHooks.styled';
import { IWebHookUrl } from '@/types/types'
import useFetchWebHooks from 'hooks/useFetchWebHooks'
import BottomButtons from './BottomButtons/BottomButtons'
import InputButton from './InputButton/InputButton'

function WebHookForm() {
  const [formValues, setFormValues] = useState<IWebHookUrl[]>([])
  const [testedWebHook, setTestedWebHook] = useState<number | null>()
  const [errorMessage, setErrorMessage] = useState<Array<string>>([])
  const [isSuccess, setIsSuccess] = useState<number | null>()
  const { webHookUrls, testWebHook } = useFetchWebHooks()

  useEffect(() => {
    setFormValues([...webHookUrls])
  }, [webHookUrls])

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const newFormValue = [...formValues]
    newFormValue[index].url = e.target.value
    setFormValues(newFormValue)
  }

  const addNewRow = (e: React.FormEvent): void => {
    e.preventDefault()
    setFormValues([...formValues, { url: '' }])
  }

  const deleteRow = (e: React.FormEvent, id: number): void => {
    e.preventDefault()
    const newFormValues = [...formValues]
    newFormValues.splice(id, 1)
    setFormValues(newFormValues)
  }

  const testWebHookUrl = async (index: number): Promise<void> => {
    setTestedWebHook(index)
    const res = await testWebHook(formValues[index].url)
    if (res !== undefined) {
      setIsSuccess(Number(res.isSuccess))
      setTimeout(() => {
        setIsSuccess(null)
        setTestedWebHook(null)
      }, 3000)
    }
  }

  return (
    <NotificationURLS method="post">
      <FormHeader>Webhooks URLs</FormHeader>
      {formValues.map((el, index) => (
        <InputContainer hasError={false} key={index}>
          {errorMessage[index] !== '' && <ErrMessage>{errorMessage[index]}</ErrMessage>}
          <input key={index} placeholder="Provide webhook URL" name='webhook' role='webhook' value={el.url} onChange={e => handleChangeInput(e, index)} />
          <InputButton
            index={index}
            testedWebHook={testedWebHook}
            isSuccess={isSuccess}
            testWebHookUrl={testWebHookUrl}
            deleteRow={deleteRow}
          />
        </InputContainer>
      ))}

      <BottomButtons
        setErrorMessage={setErrorMessage}
        addNewRow={addNewRow}
        formValues={formValues}
        setFormValues={setFormValues}
      />
    </NotificationURLS>
  )
}

export default WebHookForm

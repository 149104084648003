import { motion } from 'framer-motion'
import styled, { keyframes } from 'styled-components'


export const Container = styled.div<{ $loading: boolean }>`
  width: 100%;
  position: relative;
  height: 100%;
  margin-left: 85px;
  padding-top: 85px;
  background-color: #e5eff8;
  transition: all 0.3s ease;
`
export const ErrorContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`
export const LoaderContainer = styled.div`
  justify-content: center;
  width: 100%;
  min-height: 100%;
  background-color: #e5eff8;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
`
const spinner = keyframes`
 to {
      transform: rotate(1turn);
    }
`

export const Loader = styled.span`
  display: block;
  margin: 0;
  margin: 1rem;
  padding: 0;
  width: 90px;
  height: 90px;
  background-color: transparent;
  border-radius: 50%;
  border: 3px solid #006eff;
  border-bottom-color: transparent;
  position: relative;
  animation-name: ${spinner};
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`

export const IFrame = styled(motion.iframe)<{ $display: boolean }>`
  border: none;
  display: ${props => (props.$display ? 'block' : 'none')};
  background-color: #e5eff8;
  width: 100%;
  height: 100%;
`
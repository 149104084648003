import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import './index.css'
import App from './App'
import { UserContextProvider } from 'context/UserContext'
import { MenuContextProvider } from 'context/MenuContext'
import { ErrorModalProvider } from './context/ErrorContext'
import { TranslationsContextProvider } from './context/TranslationsContext'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <TranslationsContextProvider>
        <ErrorModalProvider>
          <MenuContextProvider>
            <UserContextProvider>
              <App />
            </UserContextProvider>
          </MenuContextProvider>
        </ErrorModalProvider>
      </TranslationsContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

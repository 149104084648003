import { useState, useContext, SetStateAction } from 'react'
import axios from 'axios';
import { IActiveAppResponse, IAppGroupResponse, IAppGroup, IActiveApp } from '@/types/types';
import { ErrorModalContext } from 'context/ErrorContext';
import { checkBrowserLanguage } from 'hooks/useFetchTranslations';

const useFetchApps = (
  setAllApps: { (value: SetStateAction<IActiveApp[]>): void; (arg0: IActiveApp[]): void; }, 
  allApps: IActiveApp[],
  setAppGroups: { (value: SetStateAction<IAppGroup[]>): void; (arg0: IAppGroup[]): void; }, 
  appGroups: IAppGroup[], 
  setActiveApps: { (value: SetStateAction<IActiveApp[]>): void; (arg0: (activeApps: IActiveApp[]) => IActiveApp[]): void; },
  activeApps: IActiveApp[]
)=> {
  const [loading, setLoading] = useState<boolean>(false);
  const { handleMenuErrorModal } = useContext(ErrorModalContext)
  const lang = checkBrowserLanguage();

  const fetchAllApps = async (input: string) => {
    setLoading(true)
    const res = await axios.get<IActiveAppResponse>(`${process.env.REACT_APP_API}AppGroups/!all?lang=${lang}`, {
      headers: {
        accept: 'text/plain',
        Authorization: `Bearer ${input}`,
      },
    })
    setLoading(false)
    const allActiveApps = res.data.items.filter(el => el.isActive)
    setAllApps([...allActiveApps])
    allActiveApps.map(el => {
      if (!activeApps.includes(el)) {
        setActiveApps([...activeApps, el])
      }
    })
  }

  const fetchAppGroups = async (input: string) => {
    try {
      if (appGroups.length) {
        return
      } else {
        setLoading(true)
        const res = await axios.get<IAppGroupResponse>(`${process.env.REACT_APP_API}AppGroups?lang=${lang}`, {
          headers: {
            accept: 'text/plain',
            Authorization: `Bearer ${input}`,
          },
        })

        setAppGroups(res.data.items)
        await fetchActiveApps(input, res.data.items)
        await fetchAllApps(input)
      }
    } catch (error: any) {
      handleMenuErrorModal(error.message)
    } finally {
      setLoading(false)
    }
  }
    
  const fetchActiveApps = async (input: string, arr: Array<IAppGroup>): Promise<void> => {
    const groupsWithActiveApps = [...arr]
    try {
      setLoading(true)
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id !== '!all') {
          const apps = await axios.get<IActiveAppResponse>(`${process.env.REACT_APP_API}AppGroups/${arr[i].id}?lang=${lang}`, {
            headers: {
              accept: 'text/plain',
              Authorization: `Bearer ${input}`,
            },
          })
          const onlyActiveApps = apps.data.items.filter(el => el.isActive)
          if (!onlyActiveApps.length) {
            const groupIndex = groupsWithActiveApps.findIndex(group => group.id === arr[i].id)
            groupsWithActiveApps.splice(groupIndex, 1)
          } 
          setActiveApps((activeApp: IActiveApp[]) => [...activeApp, ...onlyActiveApps]);
        }
      }
      setAppGroups(groupsWithActiveApps)
    } catch (error: any) {
      handleMenuErrorModal(error.message)
    } finally {
      setLoading(false)
    }
  }
  return { fetchActiveApps, fetchAllApps, fetchAppGroups, loading }
}

export default useFetchApps
import { createContext, ReactChild, ReactChildren, useContext } from 'react'
import useErrorModal from 'hooks/useErrorModal'
import { IErrorModalContext } from 'types/types'
type Props = {
  children: ReactChild | ReactChildren
}

const ErrorModalContextDefault: IErrorModalContext = {
  showError: false,
  handleErrorModal: () => null,
  content: '',
  closeModal: () => null,
  handleMenuErrorModal: () => null,
  showMenuError: false,
  menuErrorContent: '',
  closeMenuErrorModal: () => null,
  handleWebHooksError: () => null,
  showWebHooksError: false
}

export const ErrorModalContext = createContext<IErrorModalContext>(ErrorModalContextDefault)

export const ErrorModalProvider = ({ children }: Props) => {
  const {
    showError,
    handleErrorModal,
    content,
    closeModal,
    handleMenuErrorModal,
    showMenuError,
    menuErrorContent,
    closeMenuErrorModal,
    handleWebHooksError, 
    showWebHooksError
  } = useErrorModal()
  
  return (
    <ErrorModalContext.Provider
      value={{
        closeMenuErrorModal,
        menuErrorContent,
        showMenuError,
        showError,
        handleErrorModal,
        content,
        closeModal,
        handleMenuErrorModal,
        handleWebHooksError,
        showWebHooksError
      }}
    >
      {children}
    </ErrorModalContext.Provider>
  )
}

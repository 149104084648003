import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: #00000060;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100 !important;
`

export const Modal = styled.div`
  background-color: white;
  width: 400px;
  height: 250px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  p {
    background-color: #dd4c39;
    color: white;
    display: block;
    border-radius: 6px;
    width: 310px;
    text-align: center;
    position: absolute;
    top: 20px;
    padding: 6px 0px;
    transform: all 300ms ease-in-out;
  }
`

export const CloseIcon = styled(DisabledByDefaultIcon)`
  position: absolute;
  top: 5px;
  right: 5px;
  color: #34495e;
  cursor: pointer;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin-top: 1rem;
  label {
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    .icon {
      color: #38b832;
      margin-right: 0.5rem;
      width: 1.3rem;
    }
  }
  input {
    border-radius: 5px;
    border: 1px solid #34495e;
    height: 2.5rem;
    padding: 0.5rem;
    width: 100%;
  }
  button {
    background-color: white;
    border: 1.5px solid #38b832;
    border-radius: 4px;
    padding: 5px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span {
      font-size: 12px;
      margin-right: 10px;
      color: #38b832;
      font-weight: 500;
    }
    .icon {
      font-size: 1.2rem;
      color: #38b832;
    }
  }
`
export const CheckboxContainer = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: left;
  margin-bottom: 1rem;
  label {
    font-size: 10px;
    padding: 0;
    margin: 0;
    margin-left: 0.5rem;
    font-weight: 500;
  }
  input {
    width: 1rem;
    margin: 0;
    padding: 0;
    border: 0.5px solid #34495e;
    background-color: #34495e;
    cursor: pointer;
  }
`
import { useState, Dispatch, SetStateAction } from 'react'
import { IWebHookUrl } from '@/types/types'
import { validateInputData } from 'utils/WebHookHelper'
import useFetchWebHooks from 'hooks/useFetchWebHooks'
import { AddNewRow, Button, SendForm } from '../../WebHooks.styled';
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'

type Props = {
  setErrorMessage: Dispatch<SetStateAction<string[]>>
  formValues: Array<IWebHookUrl>
  setFormValues: Dispatch<SetStateAction<IWebHookUrl[]>>
  addNewRow: (e: React.FormEvent) => void
}
function BottomButtons({ setErrorMessage, formValues, setFormValues, addNewRow }: Props) {
  const [saved, setSaved] = useState<boolean>(false)
  const { addWebHook } = useFetchWebHooks()

  const errorText = 'Please provide valid URL'

  const addNewWebHookUrl = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()
    const err = validateInputData(formValues, setErrorMessage)
    if (!err.includes(errorText)) {
      await addWebHook(formValues)
      setSaved(true)
      setTimeout(() => setSaved(false), 2000)
    } else {
      setTimeout(() => {
        setErrorMessage([])
      }, 4000)
    }
  }

  return (
    <>
      <AddNewRow onClick={e => addNewRow(e)} id="add-row-btn">
        <span>Add row</span>
        <AddIcon />
      </AddNewRow>
      <SendForm>
        {saved ? (
          <Button type="submit" onClick={e => addNewWebHookUrl(e)} id="save">
            <span>Saved</span> <CheckIcon />
          </Button>
        ) : (
          <Button type="submit" onClick={e => addNewWebHookUrl(e)}>
            <p>Save</p>
          </Button>
        )}
      </SendForm>
    </>
  )
}

export default BottomButtons

/* eslint-disable @typescript-eslint/no-shadow */
import Layout from './components/Layout/Layout'
import { useEffect } from 'react'
import AppRoutes from './routes/AppRoutes'
import useAuthFormUrl from 'hooks/useAuthFormUrl';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/Errors/ErrorFallback/ErrorFallback';

function App() {
  const { authorizeUser,  } = useAuthFormUrl()

  useEffect(() => {
    authorizeUser()
  }, [])


  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => window.location.reload()}>
        <Layout>
          <AppRoutes />
        </Layout>
      </ErrorBoundary>
    </div>
  )
}

export default App

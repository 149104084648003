import NotificationsIcon from '@mui/icons-material/Notifications'
import { Container, Title } from './WebHooks.styled'
import WebHookForm from './WebHookForm/WebHookForm'
import { ErrorModalContext } from 'context/ErrorContext'
import { useContext } from 'react'
import ErrorModal from 'components/Errors/ErrorModal/ErrorModal'
import { motion } from 'framer-motion';

function WebHooks() {
  const { showWebHooksError } = useContext(ErrorModalContext)
  return (
    <Container>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='transition'>
        {showWebHooksError && <ErrorModal />}
        <Title>
          <NotificationsIcon className="icon" />
          Runner Activity Notifications
        </Title>
        <WebHookForm />
      </motion.div>
    </Container>
  )
}

export default WebHooks

import { Dispatch, SetStateAction, useContext } from 'react'
import { MenuContext } from 'context/MenuContext'
import { TranslationsContext } from 'context/TranslationsContext'
import { renderIcon } from 'utils/SidebarHelper'
import CircleIcon from '@mui/icons-material/Circle';
import { ListItem } from './AppItems.styles';
import { IActiveApp } from 'types/types'
import { motion } from 'framer-motion';

type Props = {
  tabArr: Array<string>
  list: Array<IActiveApp>
  activeShowMore: string
  setShowSecondLevelMenu: Dispatch<SetStateAction<boolean>>;
  showSecondLevelMenu: boolean
}

const AppItems = ({ tabArr, list, activeShowMore, setShowSecondLevelMenu, showSecondLevelMenu }: Props) => {
  const { activeLocation, activeTab, setActiveTab, setActiveLocation, setShowMobileMenu, setHelpContent, loading } = useContext(MenuContext);
  const { translations } = useContext(TranslationsContext)

  const clickHandler = (item: IActiveApp) => {
    setShowSecondLevelMenu(!showSecondLevelMenu)
    if (activeLocation !== '!all' && tabArr.includes(activeLocation)) {
      setActiveLocation(item.tags.items[1].displayName)
    }
    setActiveTab(item.id)
    setShowMobileMenu(false)
    setHelpContent(item.id)
  }

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      {list.length ? list.map((item, index) => (
        <ListItem 
          key={index} 
          $colorBasedOnActivity={item.isActive} 
          onClick={() => clickHandler(item)} 
          $isActiveApp={item.id === activeTab} 
          to={`/apps/${item.id}`}
        >
          <span className="icon">{renderIcon(item.id.toString().split('_')[3])}</span>
          <p>{item.displayName}</p>
          <span className='activity-icon'>
            <CircleIcon/>
          </span>
        </ListItem>
      )) : (
        !loading && 
        !list.length && 
        !tabArr.includes(activeLocation) && 
        activeShowMore !== 'showMore' && 
        <p>{translations.not_found}</p>
      )}
    </motion.div>
  )
}

export default AppItems
import { UserContext } from 'context/UserContext'
import { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import AsidePrimary from './AsidePrimary/AsidePrimary'
import AsideSecondary from './AsideSecondary/AsideSecondary'
import { MenuContext } from 'context/MenuContext'
import { useWindowDimensions } from 'hooks/useWindowDimensions'
import { calculateNumberOfMenuItems } from 'utils/SidebarHelper';
import { IAppGroup } from '@/types/types'

function Aside() {
  const { showMobileMenu, appGroups, activeLocation, setActiveLocation } = useContext(MenuContext)
  const { user } = useContext(UserContext)
  const { height } = useWindowDimensions()

  const [itemToDisplayAtFirstLevel, setItemToDisplayAtFirstLevel] = useState<IAppGroup[]>([]);
  const [itemToDisplayAtSecondLevel, setItemToDisplayAtSecondLevel] = useState<IAppGroup[]>([]);
  const [activeShowMore, setActiveShowMore] = useState<string>('');
  const [showBackground, setShowBackground] = useState(false);
  const [showSecondLevelMenu, setShowSecondLevelMenu] = useState<boolean>(false)
  const locationsWithHiddenSecondLevelMenu = ['/webhooks', '/blog', '/help'];
 
  useEffect(() => {
    console.log(showBackground)
  }, [])
  const checkWindowLocation = () => {
    const location = window.location.pathname;
    if (!showMobileMenu && locationsWithHiddenSecondLevelMenu.includes(location) && location !== activeLocation) {
      setShowBackground(true)
    } else {
      setShowBackground(false)
    }
  }


  const secondLevelMenuHandler = (location: string) => {
    if (activeLocation === location) {
      setShowSecondLevelMenu(!showSecondLevelMenu)
      setShowBackground(false)
      if (locationsWithHiddenSecondLevelMenu.includes(window.location.pathname)) {
        setActiveLocation(window.location.pathname)
      }
    } else {
      setShowSecondLevelMenu(false)
    }

  }

  useEffect(() => {
    checkWindowLocation()
  }, [activeLocation, window.location.pathname])

  useEffect(() => {
    const numberOfMenuItems = calculateNumberOfMenuItems(height)
    const appGroupsCopy = [...appGroups];
    setItemToDisplayAtFirstLevel(appGroupsCopy.splice(0, numberOfMenuItems));
    setItemToDisplayAtSecondLevel([...appGroupsCopy]);
  }, [height, appGroups]);

  useEffect(() => {
    itemToDisplayAtFirstLevel.filter(el => el.displayName === activeLocation && setActiveShowMore(''))
  }, [activeLocation])


  return (
    <AsideContainer showMobileMenu={showMobileMenu} data-testid='aside' showBackground={showBackground}>
      <AsidePrimary 
        itemToDisplayAtFirstLevel={itemToDisplayAtFirstLevel} 
        setActiveShowMore={setActiveShowMore}
        activeShowMore={activeShowMore}
        secondLevelMenuHandler={secondLevelMenuHandler}
        locationsWithHiddenSecondLevelMenu={locationsWithHiddenSecondLevelMenu}
      />
      {user && (
        <AsideSecondary 
          itemToDisplayAtSecondLevel={itemToDisplayAtSecondLevel}
          activeShowMore={activeShowMore}
          setActiveShowMore={setActiveShowMore}
          locationsWithHiddenSecondLevelMenu={locationsWithHiddenSecondLevelMenu}
          setShowSecondLevelMenu={setShowSecondLevelMenu}
          showSecondLevelMenu={showSecondLevelMenu}
        />
      )}
      {user && (
      <AsideBackground 
        showBackground={showBackground} 
        showMobileMenu={showMobileMenu}
        showSecondLevelMenu={showSecondLevelMenu}
        onClick={() => {
          setShowBackground(false);
          setShowSecondLevelMenu(!showSecondLevelMenu);
          if (locationsWithHiddenSecondLevelMenu.includes(window.location.pathname)) {
            setActiveLocation(window.location.pathname)
          }
        }}/>
      )}
    </AsideContainer>
  )
}

const AsideContainer = styled.aside<{ showMobileMenu: boolean, showBackground: boolean }>`
  background-color: yellow;
  height: 100%;
  position: fixed;
  display: flex;
  transform: translateX(0vh);
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  background-color: white;
  z-index: 8 !important;
  @media (max-width: 991px) {
    transform: ${props => (props.showMobileMenu ? 'translateX(0vh)' : 'translateX(-390px)')};
  }
`
export const AsideBackground = styled.div<{ showBackground: boolean, showMobileMenu: boolean, showSecondLevelMenu: boolean }>`
  width: 100vw;
  height: 100%;
  background-color: #0000004f;
  position: absolute;
  visibility: ${props => (props.showBackground && !props.showMobileMenu ) || (!props.showSecondLevelMenu && !props.showMobileMenu) ? 'visible' : 'hidden'};
  opacity: ${props => (props.showBackground && !props.showMobileMenu) || (!props.showSecondLevelMenu && !props.showMobileMenu)  ? '1' : '0'};
  transition: visibility 0s, opacity 0.3s ease;
  z-index: 1;
  @media (max-width: 991px) {
    display: none;
  }
`
export default Aside

import LockOpenIcon from '@mui/icons-material/LockOpen'
import { UserContext } from 'context/UserContext'
import { MenuContext } from 'context/MenuContext'
import { Container, BigLogo, MobileMenu, UserInfo, Login } from './Header.styles';
import logo from 'img/logo.svg'
import { useContext } from 'react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MenuIcon from '@mui/icons-material/Menu';
import { TranslationsContext } from 'context/TranslationsContext';

function Header() {
  const { user, logout } = useContext(UserContext)
  const { setShowModal, setShowMobileMenu, showMobileMenu } = useContext(MenuContext)
  const { translations } = useContext(TranslationsContext)
  return (
    <Container data-testid="header">
      <BigLogo>
        <img src={logo} alt="logo" />
      </BigLogo>
      <MobileMenu showMobileMenu={showMobileMenu}>
        <MenuIcon onClick={() => setShowMobileMenu(!showMobileMenu)} data-testid='mobileIcon'/>
      </MobileMenu>
      {user ? (
        <UserInfo className="user-name">
          <span>
            <AccountBoxIcon className="user-icon" />
            <p>{user}</p>
          </span>
          <ExitToAppIcon className="user-icon" onClick={() => logout()} data-testid='logout'/>
        </UserInfo>
      ) : (
        <Login>
          <button onClick={() => setShowModal(true)}>
            <span>{translations.authorize}</span>
            <LockOpenIcon className="icon" />
          </button>
        </Login>
      )}
    </Container>
  )
}

export default Header

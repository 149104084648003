import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  min-width: 86px;
  max-width: 86px;
  display: flex;
  min-height: 100%;
  max-height: 100%;
  border-right: 1px solid #e5eff8;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 5;
  .show-more {
    background-color: #959396;
    width: 60%;
    height: 1px;
    position: absolute;
    bottom: 0px;
  }
`
export const Nav = styled.div`
  min-height: 100%;
  max-height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 55px;
  background-color: white;
  justify-content: space-between;
`


import styled from 'styled-components'
import { Link } from 'react-router-dom';

export const ListItem = styled(Link)<{ $isActiveApp: boolean, $colorBasedOnActivity: boolean }>`
  text-decoration: none;
  transition: all 0.3s ease;
  color: ${props => (props.$isActiveApp ? 'white' : 'rgb(52, 73, 94)')};
  background-color: ${props => props.$isActiveApp ? '#006eff' : 'transparent'};
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  border-radius: 6px;
  display: flex;
  align-item: center;
  justify-content: space-between;
  height: fit-content;
  width: 100%;
  font-size: 10px !important;
  p {
    font-size: 10px;
    display: flex;
    text-align: left !important;
    justify-content: left;
    align-items: center;
    width: 100%;
    color: ${props => (props.$isActiveApp ? 'white' : 'rgb(52, 73, 94)')} !important;
  }
  .icon {
    display: flex;
    height: 100%;
    align-items: center;
  }
  .icon svg {
    width: 20px;
    height: 20px;
    margin-right: 1rem;
  }
  .activity-icon {
    display: flex;
    align-items: center;
    margin-left: 0.8rem;
    color: ${props => props.$colorBasedOnActivity ? '#27a700' : '#f44336'};
    svg {
      font-size: 8px;
    }
  }
`
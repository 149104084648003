import { ErrorContainer, BackLink } from './Error.styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { motion } from 'framer-motion';

type Props = {
  message: string
  status: number
  showBackButton: boolean
}

function Error({ message, status, showBackButton }: Props) {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='transition'>
      <ErrorContainer>
        <h1>{status}</h1>
        <p>{message}</p>
        {showBackButton && (
          <BackLink to="/">
            {' '}
            <span>Return Home</span>
            <ArrowBackIcon />
          </BackLink>
        )}
      </ErrorContainer>
    </motion.div>
  )
}

export default Error

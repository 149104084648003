/* eslint-disable @typescript-eslint/no-shadow */
import { IMenuContext, IAppGroup, IActiveApp } from 'types/types'
import { createContext, ReactChild, useContext, useState } from 'react'
import useFetchApps from 'hooks/useFetchApps'

type Props = {
  children: ReactChild | ReactChild[]
}

const MenuContextDefault: IMenuContext = {
  activeApps: [],
  appGroups: [],
  setActiveApps: () => null,
  setAppGroups: () => null,
  fetchAppGroups: () => null,
  fetchAllApps: () => null,
  fetchActiveApps: () => null,
  loading: false,
  activeLocation: '',
  setActiveLocation: () => null,
  setShowModal: () => null,
  showModal: false,
  activeTab: '',
  setActiveTab: () => null,
  helpContent: '',
  setHelpContent: () => null,
  allApps: [],
  setShowMobileMenu: () => null,
  showMobileMenu: false,
}

export const MenuContext = createContext<IMenuContext>(MenuContextDefault)

export function MenuContextProvider({ children }: Props) {
  const [activeApps, setActiveApps] = useState<Array<IActiveApp>>([])
  const [appGroups, setAppGroups] = useState<Array<IAppGroup>>([])
  const [activeLocation, setActiveLocation] = useState<string>('')
  const [showModal, setShowModal] = useState(false)
  const [activeTab, setActiveTab] = useState('')
  const [helpContent, setHelpContent] = useState<string>('')
  const [allApps, setAllApps] = useState<Array<IActiveApp>>([])
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false)
  
  const { fetchActiveApps, fetchAllApps, fetchAppGroups, loading } = useFetchApps(setAllApps, allApps, setAppGroups, appGroups, setActiveApps, activeApps);
  return (
    <MenuContext.Provider
      value={{
        appGroups,
        allApps,
        setActiveApps,
        setAppGroups,
        activeApps,
        fetchAppGroups,
        fetchActiveApps,
        fetchAllApps,
        loading,
        activeLocation,
        setActiveLocation,
        setShowModal,
        showModal,
        activeTab,
        setActiveTab,
        helpContent,
        setHelpContent,
        setShowMobileMenu,
        showMobileMenu,
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}

export const useMenuContext = () => useContext(MenuContext)

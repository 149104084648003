import styled from 'styled-components'
import background from 'img/home-background.svg'

export const Container = styled.div`
  background-image: url(${background});
  background-position: 100% 100%;
  background-size: auto 90%;
  background-repeat: no-repeat;
  padding: 80px 0px;
  position: relative;
  width: 100%;
  margin-top: 85px;
  margin-left: 0;
  height: calc(100vh - 85px);
  @media (max-width: 991px) {
    height: fit-content;
  }
  @media (min-width: 991px) {
    padding-left: 100px;
  }
`
export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
`
export const MainContent = styled.div`
  display: grid;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
  }
`
export const Content = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  img {
    align-self: center;
    height: 70px;
  }
  button {
    background-color: white;
    border: 1.5px solid #38b832;
    border-radius: 4px;
    padding: 10px 20px;
    display: flex;
    width: 200px;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    cursor: pointer;
    span {
      font-size: 12px;
      margin-right: 10px;
      color: #38b832;
      font-weight: 500;
    }
    .icon {
      font-size: 1.2rem;
      color: #38b832;
    }
  }
`
export const Divider = styled.div`
  height: 1px;
  width: 150px;
  background-color: #85a1bb;
  margin-top: 3rem;
`
export const Hero = styled.div`
  align-self: center;
  img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
  }
`

export const UserMessage = styled.div`
  height: calc(100% - 80px);
  width: calc(100% - 375px);
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #e5eff8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  img {
    width: 325px;
    height: 325px;
  }
  p {
    color: #85a1bb;
    font-weight: 500;
    font-size: 20px;
  }
`
import styled from 'styled-components'
import { motion } from 'framer-motion';

export const AppGroup = styled(motion.div)`
wdith: 100%;
display: flex;
flex-wrap: wrap;
align-items: center;
gap: 12px;
`;
export const NavItem = styled.button<{ isActiveGroup: boolean }>`
text-decoration: none;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
flex-direction: column;
width: 80px;
height: 80px;
padding: 0.5rem;
border: none;
border-radius: 6px;
background-color: ${props => (props.isActiveGroup ? '#006eff' : '#e5eff8')};
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
svg {
  color: ${props => props.isActiveGroup ? 'white' : 'rgb(52, 73, 94);'};
  height: 20px;
  width: 20px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
span {
  font-size: 9px;
  font-weight: 400;
  color: ${props => (props.isActiveGroup ? 'white' : 'rgb(52, 73, 94);;')};
}
`
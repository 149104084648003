/* eslint-disable @typescript-eslint/no-shadow */
import { createContext, ReactChild } from 'react'
import { ITranslationsContext } from 'types/types'
import { useFetchTranslations } from 'hooks/useFetchTranslations'

type Props = {
  children: ReactChild | ReactChild[]
}

const translationsContextDefault: ITranslationsContext = {
  translations: {},
  translationsLoading: false,
  translationsError: '',
  fetchTranslations: () => null
}

export const TranslationsContext = createContext<ITranslationsContext>(translationsContextDefault)

export function TranslationsContextProvider({ children }: Props) {

  const { translations, translationsError, translationsLoading, fetchTranslations } = useFetchTranslations()
  return (
    <TranslationsContext.Provider
      value={{
        translations, translationsError, translationsLoading, fetchTranslations
      }}
    >
      {children}
    </TranslationsContext.Provider>
  )
}

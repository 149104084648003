import AppsIcon from '@mui/icons-material/Apps';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import MoreIcon from '@mui/icons-material/More';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import TimelineIcon from '@mui/icons-material/Timeline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalculateIcon from '@mui/icons-material/Calculate';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CreditCardIcon from '@mui/icons-material/CreditCard';

export const renderIcon = (param: string) => {
  switch (param) {
    case 'orders':
      return <ShoppingBasketIcon />
    case 'ORD':
      return <ShoppingBasketIcon />
    case 'stocks':
      return <TimelineIcon />
    case 'STC':
      return <TimelineIcon />
    case 'products':
      return <MoreIcon />
    case 'PRD':
      return <MoreIcon />
    case 'prices':
      return <AttachMoneyIcon />
    case 'PRC':
      return <AttachMoneyIcon />
    case 'invoices':
      return <CreditCardIcon />
    case 'INV':
      return <CreditCardIcon />
    case 'accounting':
      return <CalculateIcon />
    case 'status':
      return <AutorenewIcon />
    case 'STA':
      return <AutorenewIcon />
    case 'shipping number':
      return <LocalShippingIcon />
    case 'PAC':
      return <LocalShippingIcon />
    case 'shipping label':
      return <LocalPostOfficeIcon />
    case 'LBL':
      return <LocalPostOfficeIcon />
    default:
      return <AppsIcon />
  }
}

export const calculateNumberOfMenuItems = (height: any) => {
  if (height !== null) {
    if (height <= 590) {
      return 0
    } else if (height > 590 && height < 650) {
      return 1
    } else if (height > 650 && height < 710) {
      return 2
    } else if (height > 710 && height < 770) {
      return 3
    } else if (height > 770 && height < 890) {
      return 4
    } else if (height > 890 && height < 950) {
      return 5
    } else if (height > 950 && height < 1010) {
      return 6
    } else if (height > 1010 && height < 1100) {
      return 7
    } else if (height > 1100 && height < 1200) {
      return 8
    } else if (height > 1200 && height < 1300) {
      return 9
    } else { 
      return 10 
    }
  }
}

import { renderCorrectIcon } from 'utils/WebHookHelper'
import { ButtonContainer, TestButton } from '../../WebHooks.styled'
import React from 'react'
import RemoveIcon from '@mui/icons-material/Remove'
import useFetchWebHooks from 'hooks/useFetchWebHooks'

type Props = {
  index: number
  testedWebHook: any
  isSuccess: any
  testWebHookUrl: (c: number) => Promise<void>
  deleteRow: (e: React.FormEvent, id: number) => void
}
function InputButton({ index, testedWebHook, isSuccess, testWebHookUrl, deleteRow }: Props) {
  const { loading } = useFetchWebHooks()

  return (
    <ButtonContainer>
      <button onClick={e => deleteRow(e, index)} data-testid="remove-button">
        <RemoveIcon />
      </button>
      <TestButton
        onClick={() => testWebHookUrl(index)}
        isSuccess={testedWebHook === index && isSuccess}
        disabled={loading}
        data-testid="test-button"
      >
        {renderCorrectIcon(testedWebHook, index, isSuccess, loading)}
      </TestButton>
    </ButtonContainer>
  )
}

export default InputButton

import { Container } from './ErrorFallback.styles';
import ReplayIcon from '@mui/icons-material/Replay';
type Props = {
  error: any
  resetErrorBoundary: () => void
}
const ErrorFallback = ({ error, resetErrorBoundary }: Props) => {
  return (
    <Container>
        <p>Oops! Something went wrong.</p>
        <span>{error.message}</span>
        <button onClick={() => resetErrorBoundary()}>Try again <ReplayIcon /></button>
    </Container>
  )
}

export default ErrorFallback
import styled from 'styled-components'

export const SearchForm = styled.div`
width: 100%;
display: flex;
background-color: #f7fafd;
justify-content: center;
position: relative;
color: #85a1bb;
margin: 1rem 0rem;
input {
  border: none;
  width: 100%;
  color: #141543;
  padding: 0.7rem 2rem;
  border-radius: 30px;
  font-weight: 400;
  font-size: 14px;
  background-color: #e5eff8;
  transition: color 0.2s ease, background-color 0.2s ease;
  &:focus {
    outline: none;
    color: #141832;
    background: white;
    box-shadow: 0 0 1px 1px #006eff;
    transition: color 0.2s ease, background-color 0.2s ease;
  }
}
label {
  position: absolute;
  top: 6px;
  right: 15px;
  color: #85a1bb;
  svg {
    width: 25px;
    height: 25px;
  }
}
`
/* eslint-disable no-useless-catch */
import { IWebHookUrl } from 'types/types'
import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { UserContext } from 'context/UserContext'
import { ErrorModalContext } from 'context/ErrorContext'

export default function useFetchWebHooks() {
  const [webHookUrls, setWebHookUrls] = useState<IWebHookUrl[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const { token } = useContext(UserContext)
  const { handleWebHooksError } = useContext(ErrorModalContext)

  useEffect(() => {
    const fetchWebHooksUrls = async () => {
      setLoading(true)
      try {
        const res = await axios.get(`${process.env.REACT_APP_API}WebHooks/EndPoints`, {
          headers: {
            accept: 'text/plain',
            Authorization: `Bearer ${token}`,
          },
        })
        setWebHookUrls(res?.data?.urls)
      } catch (error: any) {
        handleWebHooksError(error.message)
      } finally {
        setLoading(false)
      }
    }
    fetchWebHooksUrls()
  }, [])

  const addWebHook = async (webHooks: any) => {
    setLoading(true)
    const data = JSON.stringify({
      urls: webHooks,
    })
    try {
      const res = await axios.post(`${process.env.REACT_APP_API}WebHooks/EndPoints`, data, {
        headers: {
          accept: 'text/plain',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json, charset=UTF-8',
        },
      })
      if (res.status === 500) {
        handleWebHooksError('500 - Internal Server Error. Please try again later.')
      } else {
        setWebHookUrls(res.data.urls)
        return res
      }
    } catch (error) {
      handleWebHooksError('Something went wrong.')
    }
  }

  const testWebHook = async (id: string) => {
    setLoading(true)
    const data = JSON.stringify({
      url: id,
    })
    try {
      const res = await axios.post(`${process.env.REACT_APP_API}WebHooks/SendTestMessage`, data, {
        headers: {
          accept: 'text/plain',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json, charset=UTF-8',
        },
      })
      if (res.status !== 200) {
        handleWebHooksError('Something went wrong.')
      } else {
        return res.data
      }
    } catch (error) {
      handleWebHooksError('Something went wrong.')
    } finally {
      setLoading(false)
    }
  }

  return { addWebHook, testWebHook, webHookUrls, setWebHookUrls, loading }
}
